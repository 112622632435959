import styled from "styled-components";
import { device } from "../../style/responsive";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 37.5rem;
  width: 100%;
  background-image: linear-gradient(to bottom, var(--grey), var(--white) 50%);
`;

export const ResourcesItems = styled.div`
  width: 75%;

  @media only screen and ${device.tabLand} {
    width: 90%;
  }
  h4 {
    font-size: 1rem;
    color: var(--blue-dark);
    padding: 4rem 0 2rem 0;
  }

  .resources {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media only screen and ${device.tabPort} {
      grid-template-columns: 1fr;
    }
  }

  .resources__item {
    display: flex;
    padding: 2rem 2rem 2rem 0;
    .feature {
      margin-right: 1rem;
    }
    .definition {
      display: flex;
      flex-direction: column;
      width: 100%;

      h3 {
        font-size: 1.5rem;
        font-weight: 900;
      }

      p {
        @media only screen and ${device.tabPort} {
          font-size: 1rem;
        }
      }
    }
  }
`;
