import React from "react";
import { useTranslation } from "react-i18next";
import Menu from "../Menu";
import { Container, HeadingSection } from "./styles";
import { PrimaryButton, SecondayButton } from "../../components/buttons/styles";
import illustration from "../../assets/illustration-fixplay.svg";

const Heading = () => {
  const { t } = useTranslation();
  return (
    <HeadingSection>
      <div className="headingText">
        <h1>{t("hero-title")}</h1>
        <p>{t("hero-text")}</p>
        <div className="btnHero">
          <PrimaryButton to="/eventos">{t("hero-btn-1")}</PrimaryButton>
          <SecondayButton to="/organizador/login">
            {t("hero-btn-2")}
          </SecondayButton>
        </div>
      </div>
      <div className="illustration">
        <img
          src={illustration}
          alt={t("alt-illustration1")}
        ></img>
      </div>
    </HeadingSection>
  );
};
const Hero = () => {
  return (
    <>
      <Container>
        <Menu />
        <Heading />
      </Container>
    </>
  );
};

export default Hero;
