import styled from "styled-components";

export const Container = styled.div`
  background-color: #000;
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  position: relative;

  .systemTransmission {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .systemInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
      }
    }
  }
`;
