import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { credentials } from "../../../auth/credentials";
import Menu from "../../../layout/Menu";
import { Container } from "./styles";
import { SystemButton } from "../../../components/buttons/styles";
import Footer from "../../../layout/Footer";
import arrowBack from "../../../assets/icon-arrow-left.svg";
import iconAlert from "../../../assets/icon-alert.svg";

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [token, setToken] = useState("");
  const [storage, setStorage] = useState(localStorage.getItem("key") || "");
  const [alert, setAlert] = useState(false);

  const login = credentials.broadcaster;

  useEffect(() => {
    setStorage(token);
    localStorage.setItem("key", token);
  }, [storage, token]);

  const handleLogin = (e) => {
    e.preventDefault();

    if (token !== login) {
      setAlert(true);
    } else {
      history.push("/organizador/eventos-disponiveis");
    }
  };
  return (
    <Container>
      <Menu />
      <div className="main">
        <div className="heading">
          <h2>{t("host-login-title")}</h2>
          <p>
            {t("host-login-subtitle")} &nbsp;
            <Link to="/cliente-area">{t("host-login-subtitle-link")}</Link>
          </p>
        </div>
        <form className="login-organizador" onSubmit={handleLogin}>
          {/* <label className="label" htmlFor="login">
            Login
          </label>
          <input
            className="login"
            type="text"
            id="login"
            placeholder={t("host-login-input-email-placeholder")}
          /> */}
          <label className="label" htmlFor="passwd">
            {t("host-login-input-password-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-login-input-password-placeholder")}
              onChange={(e) => {
                setToken(e.target.value);
              }}
            />
            <SystemButton>{t("host-login-btn-confirm")}</SystemButton>
          </div>
          {alert && (
            <div className="alert">
              <small>
                <img src={iconAlert} alt="ícone de alerta" />{" "}
                {t("host-login-btn-error")}
              </small>
            </div>
          )}
        </form>
        <div className="back">
          <Link to="/">
            <img src={arrowBack} alt="Voltar" />
            {t("btn-back")}
          </Link>
        </div>
        <Footer />
      </div>
    </Container>
  );
};

export default Login;
