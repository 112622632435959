import styled from "styled-components";
import { device } from "../../style/responsive";

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-bottom: 2rem;

  @media only screen and ${device.tabPort} {
    flex-direction: column-reverse;
    width: 90%;
  }

  .tech-illustration {
    margin-top: 4rem;
    img {
      width: 22.5rem;
      height: 29.93rem;

      @media only screen and ${device.tabLand} {
        width: 15.25rem;
        height: 20.31rem;
      }
    }
  }

  .heading-section {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media only screen and ${device.tabPort} {
      width: 100%;
    }
    h4 {
      font-size: 1rem;
      color: var(--blue-dark);
      padding-bottom: 1rem;

      @media only screen and ${device.tabLand} {
        padding-top: 4rem;
      }
    }

    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
      color: #000;
    }

    p {
      font-size: 1rem;
      color: #000;
      font-weight: 500;
      padding: 1rem 0;
    }
  }
`;
