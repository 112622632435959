import styled from "styled-components";
import { device } from "../../style/responsive";
import bgIlustration from "../../assets/illustration-fixplay.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 37.5rem;
  width: 100%;
  background-color: var(--blue-dark);

  @media only screen and ${device.tabLand} {
    background-image: linear-gradient(
        rgba(23, 81, 185, 0.93) 100%,
        rgba(38, 247, 253, 0.3)
      ),
      url(${bgIlustration});
  }
`;

export const HeadingSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;

  @media only screen and ${device.tabPort} {
    flex-direction: column;
    width: 90%;
  }

  .headingText {
    color: #fff;

      h1{
        @media only screen and ${device.tabPort} {
            font-size: 2.5rem;
            line-height: 3rem;
  }
        @media only screen and ${device.smallPhone} {
            font-size: 2rem;
            line-height: 24px;
  }
      }
  }

    p {
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 4rem;

      @media only screen and ${device.smallPhone} {
            font-size: 1.5rem;
            
  }
    }

    .btnHero {
      display: flex;
      justify-content: start;
    }
  }

  .illustration {
    width: 26rem;
    height: 26rem;

    @media only screen and ${device.tabLand} {
      display: none;
      visibility: hidden;
    }
  }
`;
