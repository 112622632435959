import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../style/responsive";

export const PrimaryButton = styled(Link)`
  width: 16.5rem;
  height: 3.75rem;
  border-radius: 8px;
  background-color: var(--yellow-light);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  margin-right: 1rem;
  text-decoration: none;
  cursor: pointer;


  @media only screen and ${device.tabLand} {
    width: 15rem;
    font-size: 1rem;
    /* margin-right: 1rem; */
  }
  
  @media only screen and ${device.tabPort} {
    width: 14rem;
    font-size: 0.8rem;
  }

  @media only screen and ${device.smallPhone} {
    width: 14rem;
    font-size: 0.6rem;
  }
`;

export const SecondayButton = styled(Link)`
  width: 16.5rem;
  height: 3.75rem;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;


  @media only screen and ${device.tabLand} {
    width: 15rem;
    font-size: 1rem;
  }

  @media only screen and ${device.tabPort} {
    width: 14rem;
    font-size: 0.8rem;
  }

  @media only screen and ${device.smallPhone} {
    width: 14rem;
    font-size: 0.6rem;
  }
`;

export const ContactButton = styled.a`
  width: 16.5rem;
  height: 3.75rem;
  border-radius: 8px;
  background-color: var(--blue-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 2rem;
  cursor: pointer;

  @media only screen and ${device.tabLand} {
    width: 100%;
  }
`;

export const SocialButton = styled.a`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 8px;
  border: solid 1px var(--blue-dark);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SystemButton = styled.button`
  width: 16.5rem;
  height: 3.75rem;
  border-radius: 8px;
  background-color: var(--yellow-light);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-decoration: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  outline: none;
  border: none;
  font-size: 1rem;

  @media only screen and ${device.tabLand} {
    width: 100%;
    height: 3.75rem;
    font-size: 1rem;
    margin-top: 1rem;
  }
`;
