import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";
import techIlustration from "../../assets/illustration-cloud-fixplay.svg";

const AboutTech = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="tech-illustration">
        <img
          src={techIlustration}
          alt={t("alt-illustration2")}
        />
      </div>
      <div className="heading-section">
        <h4>{t("about-tech-title1")}</h4>
        <h2>{t("about-tech-title2")}</h2>
        <p>
        {t("about-tech-text")}
        </p>
      </div>
    </Container>
  );
};

export default AboutTech;
