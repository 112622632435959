import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../../layout/Footer";
import { Container } from "./styles";
import Menu from "../../../layout/Menu";
import Card from "../../../components/Card";
import leftArrow from "../../../assets/icon-arrow-left.svg";
import iconAdSmall from "../../../assets/icon-adNoBorder.svg";
import iconSubtitleSmall from "../../../assets/icon-cc-small.svg";
import iconLibrasSmall from "../../../assets/icon-handsSmall.svg";
import iconTranslationSmall from "../../../assets/icon-translation-small.svg";
import volumeIcon from '../../../assets/audioguia_small.svg'

import { SystemButton } from "../../../components/buttons/styles";
const ClientResources = () => {
  const history = useHistory();
  const { t } = useTranslation();

  // const handleResource = (route) => {
  //   history.push(route);
  // };

  const handleResource = (route, name) => {
    const width = window.screen.width;
    const height = window.screen.height;
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=400,height=600,screenX=${height + 100},screenY=${width - 50}`;

    window.open(route, name, params);

   
  };

  return (
    <>
      <Container>
        <Menu />
        <main className="event">
          <div className="event__top">
            <h2>{t("client-area-title")}</h2>
            <p>{t("client-area-subtitle")}</p>
          </div>

          <div className="event__available">
            <h4>{t("client-area-chooseEvent")}</h4>
            <Card
             clientEvent="Opus Entretenimento"
             eventName="O Vison Voador"
             eventDate="21/10/2023"
             eventHour="17:00"
             ad
            />

            <div className="resourcesTitle">
              <h4>{t("client-resources-chosseTypeResources")}</h4>
            </div>

            <div className="resources">

              <SystemButton
                className="resources__card"
                onClick={() => handleResource("/ovisonvoador/ad", "ad")}
              >
                <img src={iconAdSmall} alt="Ícone de audiodescrição" />
                <p>{t("audiodescription-title")}</p>
         
              </SystemButton>

              {/* <SystemButton className="resources__card">
              <a
                  href="https://beplay-app.web.app/#/categories/1/events/8/room/1/idioma/1/accessibility/1/professional/10/audio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconAdSmall} alt="Ícone de audiodescrição" />
                    <p>{t("audiodescription-title")}</p>
                    </a>
                </SystemButton> */}


              {/* <SystemButton className="resources__card">
              <a
                  href="https://beplay-app.web.app/#/categories/1/events/5/room/1/idioma/1/accessibility/2/professional/9/video"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconSubtitleSmall} alt="Ícone de legendas" />
                    <p className="baseline">{t("subtitle-title")}</p>
                    </a>
                </SystemButton> */}

                

                {/* <SystemButton className="resources__card">
              <a
                  href="https://conference.smt-on.com/web/viewers/fullscreen?provider=Ktalise&channel=Ktalise%202"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconSubtitleSmall} alt="Ícone de legendas" />
                    <p className="baseline">{t("subtitle-title2")}</p>
                    </a>
                </SystemButton> */}

                {/* <SystemButton className="resources__card">
              <a
                  href="https://beplay-app.web.app/#/categories/1/events/8/room/1/idioma/1/accessibility/2/professional/6/video"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconLibrasSmall} alt="Ícone de libras" />
                    <p>{t("libras-title")}</p>
                    </a>
                </SystemButton> */}

  


              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/umanoitenabroadway/libras", "libras")}
              >
                <img src={iconLibrasSmall} alt="Ícone de libras" />
                <p>{t("libras-title")}</p>
              </SystemButton> */}

              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/festivalpoprua/ingles", "trad-ingles")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("trad-ingles")}</p>
          
              </SystemButton>  */}
              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/expocine/portugues", "trad-portugues")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("trad-portugues")}</p>
          
              </SystemButton>  */}
              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/mondelez/ad", "trad-frances")}
              >
                <img src={iconAdSmall} alt="Ícone de tradução" />
                <p>{t("audiodescription-title")}</p>
          
              </SystemButton>  */}
              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/festivalpoprua/espanhol", "trad-espanhol")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("trad-espanhol")}</p>
          
              </SystemButton>  */}
              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/ATmedios/espanhol-ingles", "trad-espanhol")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("trad-ingles")}</p>
          
              </SystemButton>  */}
 
              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/TMF2022/ingles-portugues", "trad-portugues")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("trad-portugues")}</p>
          
              </SystemButton>  */}

            </div>
          </div>
        </main>
        <div className="back">
          <Link to="/eventos">
            <img src={leftArrow} alt="Voltar" />
            {t("btn-back")}
          </Link>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ClientResources;
