import styled from 'styled-components';
import {device } from '../../../style/responsive'
export const Container = styled.div`
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;

    #play {
    width: 100%;
    height: 100vh;
    background-color: #fff;
  }

  .play {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and ${device.tabPort}{
        flex-direction: column;
    }
  }

  video {
    height: 100vh;
    /* padding: 1rem; */
    background-color: #fff;
    @media only screen and ${device.tabPort}{
      padding: 0;
      height: 0;
      min-height: 100vh;
    }
  }
`;