import React from 'react';
import { useTranslation } from "react-i18next";
import {PrimaryButton} from '../../components/buttons/styles'
import {Container} from './styles';
import Menu from '../../layout/Menu-system';

const NotFound = () =>{
    const {t} = useTranslation();
    return(
        <Container>
            <Menu />
            <div className="center">
                <h2>{t("page-notFound")}</h2>
                <PrimaryButton to="/">{t("direct-to-home")}</PrimaryButton>
            </div>
        </Container>
    );
}

export default NotFound;