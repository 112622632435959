import React, { useState, useEffect } from "react";
import { useFlag } from "../../context/flag";
import { useTranslation } from "react-i18next";
import { Nav } from "./styles";
import Logo from "../../components/Logo";
import brazilFlag from "../../assets/brazil.png";
import EnglishFlag from "../../assets/english.png";
import SpanishFlag from "../../assets/spanish.png";
import iconArrowDown from "../../assets/icon-arrow-down.svg";

const Menu = () => {
  const { flag, setFlag } = useFlag();
  const [menu, setMenu] = useState("none");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const i18nextLng = localStorage.getItem("i18nextLng");
    switch (i18nextLng) {
      case "ptBR":
        setFlag(brazilFlag);
        break;
      case "en":
        setFlag(EnglishFlag);
        break;
      case "en-GB":
        setFlag(EnglishFlag);
        break;
      case "es":
        setFlag(SpanishFlag);
        break;
      default:
        setFlag(brazilFlag);
    }
  }, []);
  const handleLanguage = (e) => {
    e.preventDefault();
    const newLng = e.target.value;
    localStorage.setItem("language", newLng);

    switch (newLng) {
      case "ptBR":
        setFlag(brazilFlag);
        break;
      case "en":
        setFlag(EnglishFlag);
        break;
      case "es":
        setFlag(SpanishFlag);
        break;
      default:
        setFlag(brazilFlag);
    }
    i18n.changeLanguage(newLng);

    toggleMenu();
  };

  const toggleMenu = () => {
    if (menu === "none") {
      setMenu("flex");
    } else {
      setMenu("none");
    }
  };

  return (
    <>
      <Nav>
        <Logo />
        <div className="chooseLanguage">
          <span className="span">{t("menu-span")}:</span>
          <img src={flag} title="flag" alt="flag" />
          <button className="btnMenu" onClick={() => toggleMenu()}>
            <img src={iconArrowDown} title="abrir menu" alt="abrir menu" />
          </button>
          <div className="dropMenu">
            <form style={{ display: menu }} onChange={handleLanguage}>
              <div className="itemMenuBR">
                <input type="radio" id="ptBR" name="lng" value="ptBR" />
                <label htmlFor="ptBR" className="label">
                  Português
                </label>
              </div>

              <div className="itemMenuEN">
                <input type="radio" id="en" name="lng" value="en" />
                <label htmlFor="en" className="label">
                  English
                </label>
              </div>

              <div className="itemMenuES">
                <input type="radio" id="es" name="lng" value="es" />
                <label htmlFor="es" className="label">
                  Español
                </label>
              </div>
            </form>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default Menu;
