import styled from "styled-components";
import { device } from "../../../style/responsive";

export const Container = styled.div`
  background-color: #000;
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  position: relative;

  .systemTransmission {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media only screen and ${device.tabLand} {
      position: relative;
      left: 0;
      top: 0;
      transform: translate(0, 0);
      margin-top: 5rem;
      width: 100%;
    }

    .systemInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h3 {
        padding: 1rem;
      }

      p {
        @media only screen and ${device.tabLand} {
          padding: 0 2rem;
        }
      }
    }

    .resources {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      @media only screen and ${device.tabLand} {
        align-items: center;
        justify-content: center;
      }

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2rem 0.5rem 0 0;
        width: 7.25rem;
        height: 6.25rem;
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.5rem;
        border-radius: 8px;
        background-color: var(--yellow-light);
        color: var(--blue-dark);

        @media only screen and ${device.tabLand} {
          width: 8rem;
          height: 7.5rem;
          margin: 0.5rem 0.5rem 0 0;
        }
      }

      &__card:last-child {
        margin: 2rem 0rem 0 0;

        @media only screen and ${device.tabLand} {
          margin: 0.5rem 0.5rem 0 0;
        }
      }

      a {
        text-decoration: none;
        color: var(--blue-dark);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
