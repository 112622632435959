import styled from "styled-components";
import { device } from "../../style/responsive";

export const Container = styled.div`
  padding: 0 0;
  img {
    @media only screen and ${device.tabPort} {
      width: 8rem;
      height: 8rem;
      padding-left: 1rem;
      padding-top: 1rem;
    }
  }
`;
