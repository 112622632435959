export const auth = {
    // appId: "966b24ac02ae4f64976ba5ec5335d6b7",
    appId: "ceb620e0e0ba494982c2c5e1af7aeb5f",
    appIdSecondary: "89e140c199834bf78dadf792e6c9f3e0",
    appIdLibras: "ceb620e0e0ba494982c2c5e1af7aeb5f",
    appIdAdPlusOriginal: "613c0ccbd600463ba264a39f7ea0879d",
    appIdTranslation: "613c0ccbd600463ba264a39f7ea0879d",
    appIdLibrasDemo: "460b9619da2a4253876a23da24d9d303",
    channel: "Audiodescription",
    channelAdSecondary: "Audiodescription_secondary",
    channelLibras: "clarobr_libras",
    channelLibrasDemo: "Libras_demo",
    channelTranslate2: "Translate_Secondy",
    channelTranslate: "Translate",
    channelCurso1: "Traducao_opus",
    channelCurso2: "Curso_2",
    channelEvent4: "teatro_opus",
    channelEvent10: "opus1",
    channelEvent11: "opus2",
    channelEvent12: "opus3",
    channelEvent13: "beplay13",
    channelEvent14: "beplay14",
    channelEvent15: "beplay15",
    channelEvent16: "beplay16",
    channelEvent17: "beplay17",
    channelEvent18: "beplay18",
    channelEvent19: "beplay19",
    channelEvent14_translate: "beplay14_translate",
    channelEvent15_translate: "beplay15_translate",
    channelLibras3: "libras_3",
    channelParelheiros: "clarobr_ad",
    channelPreview: "preview",
    channelMBoiMirim: "M Boi Mirim",
    channelCampoLimpo: "Campo Limpo",
    channelSantoAmaro: "Santo Amaro",
    channelCidadeAdemar: "Cidade Ademar",
    channelButanta: "Butanta",
    channelPinheiros: "Pinheiros",
    channelAudioguia: "audioguia",
    channelTeste:"454641611",
    appIdTeste: "8176fc2bc38a46079225dc1f7197b544"
}

//ceb620e0e0ba494982c2c5e1af7aeb5f    - principal

//966b24ac02ae4f64976ba5ec5335d6b7

//8176fc2bc38a46079225dc1f7197b544