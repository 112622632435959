import axios from 'axios';

export const api = axios.create({
    baseURL: "https://api.agora.io/v1/apps",
    auth: {
        username: "503802c4f6ba4350a849d9468db30c8c",
        password: "5ddadc014a6c42edbfaf67fd1b1d0e2f"
    }
})

export const server = axios.create({
    baseURL: "https://beplay-api.herokuapp.com/"
    // baseURL: "http://localhost:4001"
})

