import styled from "styled-components";

export const Container = styled.div`
position: relative;
`;

export const Iframe = styled.video`
 height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const IframeVideo = styled.iframe`
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 16px;
`
