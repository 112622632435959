import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {credentials} from '../../../auth/credentials';
import MenuSystem from "../../../layout/Menu-system";
import { Container } from "./styles";
import iconAdSmall from "../../../assets/icon-adNoBorder.svg";
import iconSubtitleSmall from "../../../assets/icon-cc-small.svg";
import iconLibrasSmall from "../../../assets/icon-handsSmall.svg";
import iconTranslationSmall from "../../../assets/icon-translation-small.svg";

const HostSetupResource = () => {

  const {t} = useTranslation();
  const history = useHistory();
  const key = localStorage.getItem("eventKey");
  if(key !== credentials.broadcasterMBoiMirim){
    history.push("/organizador/eventos-disponiveis")
  }
  return (
    <Container>
      <MenuSystem  />
      <div className="systemTransmission">
        <div className="systemInfo">
          <h3>{t("host-setup-resource-title")}</h3>
          <p>{t("host-setup-resource-subtitle")}</p>
          <div className="resources">

            <div className="resources__card">
              <Link
                to="/organizador/eventos/transmitir/80adecadadovaletudo/ad"
                id="audiodescription"
              >
                <img src={iconAdSmall} alt="Ícone de audiodescrição" />
                <p>{t("audiodescription-title")}</p>
              </Link>
            </div>

            {/* <div className="resources__card">
              <a
                href="https://www.streamtext.net/player?event=altmonlatam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconSubtitleSmall} alt="Ícone de legendas" />
                <p className="baseline">{t("subtitle-title")}</p>
              </a>
            </div> */}

            {/* <div className="resources__card">
              <Link to="/organizador/eventos/transmitir/flima/libras">
                <img src={iconLibrasSmall} alt="Ícone de libras" />
                <p>{t("libras-title")}</p>
              </Link>
            </div> */}

            
              {/* <div className="resources__card">
                <Link to="/organizador/eventos/transmitir/traducao/demo">
                  <img src={iconTranslationSmall} alt="Ícone de tradução" />
                  <p>{t("translate-title")}</p>
                </Link>
              </div> */}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default HostSetupResource;
