import React, { createContext, useState, useContext } from "react";

const UserLink = createContext();

export default function UserLinkProvider({ children }) {
  const [lock, setLock] = useState(true);
  return (
    <UserLink.Provider
      value={{ lock, setLock }}
    >
        {children}
    </UserLink.Provider>
  );
}

export function useLinkUser() {
  const context = useContext(UserLink);
  const { lock, setLock } = context;
  return { lock, setLock };
}
  