import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";
import iconAd from "../../assets/icon-ad.svg";
import iconACc from "../../assets/icon-cc.svg";
import iconLibras from "../../assets/icon-hands.svg";
import iconTranslate from "../../assets/icon-translation.svg";
import volumeIcon from '../../assets/icon-ad-2.svg'

const Card = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Container to={props.link ? props.link : "#"}>
        <div className="card">
          <div className="card__name">
            <h4 className="h4">{props?.clientEvent}</h4>
          </div>
          <div className="card__event-name">
            <h2>{props.eventName}</h2>
          </div>
          <div className="card__event-time">
            {props.eventDate !== '' && (
              <span className="date">{props.eventDate}</span>
              )}
              {props.eventHour !== '' && (
                <span className="hour">{props.eventHour}</span>
              )}
            {props.restriction && (
              <span className="restrict">EVENTO RESTRITO</span>
            )}
          </div>
          <div className="card__resources">
            <span>{t("card-available-accessibility")}:</span>
            <div className="card__resources__icons">
              <ul>
                <li style={{ display: props.ad ? "block" : "none" }}>
                  <img src={iconAd} alt="Ícone audiodescrição" />
                </li>
                <li style={{ display: props.ad2 ? "block" : "none" }}>
                  <img src={volumeIcon} alt="Ícone do audioguia" />
                </li>
                <li style={{ display: props.ad3 ? "block" : "none" }}>
                  <img src={iconAd} alt="Ícone audiodescrição" />
                </li>
                <li style={{ display: props.subtitle ? "block" : "none" }}>
                  <img src={iconACc} alt="Ícone Legendas" />
                </li>
                <li style={{ display: props.subtitle2 ? "block" : "none" }}>
                  <img src={iconACc} alt="Ícone Legendas" />
                </li>
                <li style={{ display: props.subtitle3 ? "block" : "none" }}>
                  <img src={iconACc} alt="Ícone Legendas" />
                </li>
                <li style={{ display: props.libras ? "block" : "none" }}>
                  <img src={iconLibras} alt="Ícone Libras" />
                </li>
                <li style={{ display: props.translate ? "block" : "none" }}>
                  <img src={iconTranslate} alt="Ícone Tradução" />
                </li>
                <li style={{ display: props.translate2 ? "block" : "none" }}>
                  <img src={iconTranslate} alt="Ícone Tradução" />
                </li>
                <li style={{ display: props.translate3 ? "block" : "none" }}>
                  <img src={iconTranslate} alt="Ícone Tradução" />
                </li>
                <li style={{ display: props.translate4 ? "block" : "none" }}>
                  <img src={iconTranslate} alt="Ícone Tradução" />
                </li>
                <li style={{ display: props.translate5 ? "block" : "none" }}>
                  <img src={iconTranslate} alt="Ícone Tradução" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Card;
