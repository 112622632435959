import styled from "styled-components";
import { device } from "../../style/responsive";

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 75%;

  @media only screen and ${device.tabPort} {
    width: 100%;
    height: 4rem;
  }

  .back {

    button{
      display:flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: 1px solid #fff;
      outline: none;
      border-radius: 8px;
      color: #fff;
      padding: .5rem;
      font-size: 1rem;
      cursor: pointer;
      img {
        margin-right: 0.5rem;
      }
    }
    margin-right: 0.5rem;
    a {
      color: #fff;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #fff;
      border-radius: 8px;
      padding: 0.5rem;

      img {
        margin-right: 0.5rem;
      }
    }
  }
`;
