import styled from "styled-components";

export const Container = styled.div`
  background-color: #000;
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  position: relative;

 
  .settings{
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
   
  }

  .systemTransmission {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    margin-top: 4rem;

    .systemInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  #play{
    height: 100vh;
    margin-top: 1rem;
  }

  .btn-libras-transmission{
    display:flex;
    padding-left: 1rem;
  }

  .transmission-users-info{
    display: flex;
  }

  .online-users{
    padding: 1rem;
  }
`;

export const ListCameraContainer = styled.div`
  position:absolute; 
  display: ${props => props.show ? "none" : "block"};
  top: 1rem;
  right: 2rem;
  z-index: 9999;

`

export const ListCamera = styled.ul`
  list-style-type: none;
  z-index: 9999;

`

export const ListCameraItem = styled.li`
text-decoration: none;
cursor: pointer;
padding: 0.5rem 0;
color: yellow;
border: 1px solid #ccc;
z-index: 9999;
`
