import styled from "styled-components";
import { device } from "../../../style/responsive";

export const Container = styled.div`
  background-color: #000;
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  position: relative;

  .systemTransmission {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .systemInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
      }

      .live {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .live-paragraph {
          padding-right: 0.5rem;
          font-family: Heebo;
          font-size: 1rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #03ff00;
        }
      }

      .mic{
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .transmission-users-info {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    bottom: 1rem;
    left: 10rem;
    @media only screen and ${device.tabLand} {
      left: 6rem;
    }
    @media only screen and ${device.tabPort} {
      left: 2rem;
    }

    p {
      color: #fff;
    }

    .online-users{
      display:flex;
      img{
        margin-right: .5rem;
      }
    }
  }
`;
