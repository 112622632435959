import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../../layout/Footer";
import { Container } from "./styles";
import Menu from "../../../layout/Menu";
import Card from "../../../components/Card";
import leftArrow from "../../../assets/icon-arrow-left.svg";

const Client = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Menu />
        <main className="event">
          <div className="event__top">
            <h2>{t("client-area-title")}</h2>
            <p>{t("client-area-subtitle")}</p>
          </div>

          <div className="event__available">
            <h4>{t("client-area-chooseEvent")}</h4>


            {/* <Card
              link="/bachianas-encerramento/recursos"
              clientEvent="Fundação Bachianas"
              eventName="Fundação Bachianas Apresentações de Encerramento 2023"
              eventDate=""
              eventHour=""
              ad
          /> */}

  

            {/* <Card
              link="/sescsantana/recursos"
              clientEvent="Sesc Santana"
              eventName="Luvas e Anéis"
              eventDate="11/11/2023"
              eventHour="20:00"
              subtitle
          /> */}

 

          <Card
              link="/atmedios"
              clientEvent="ATmedios"
              eventName="SONDA Conferencia de Resultados 2Q24 y 6M24"
              eventDate="26/07/2024"
              eventHour="09:00"
              translate
          />

          {/* <Card
              link="/teatrobangu"
              clientEvent=""
              eventName="Teatro Bangu Shopping"
              eventDate="19/11/2023"
              eventHour=""
              ad
          /> */}

            {/* <Card
                link="/homemcomh"
                clientEvent="Opus Entretenimento"
                eventName="Homem com H"
                eventDate="14/10/2023"
                eventHour=""
                ad
                subtitle
              /> */}

              {/* <Card
                link="/ssvemai"
                clientEvent="Opus Entretenimento"
                eventName="Silvio Santos"
                eventDate="15/10/2023"
                eventHour=""
                ad
                subtitle
              /> */}

            {/* <Card
              link="/netflixnaccxp/recursos"
              clientEvent="NETFLIX"
              eventName="NETFLIX na CCXP"
              eventDate="29/11/2023"
              eventHour="11:00"
              ad
          /> */}

        {/* <Card 
              link="/ovisonvoador"
              clientEvent="Opus Entretenimento"
              eventName="O Vison Voador"
              eventDate="21/10/2023"
              eventHour="17:00"
              ad
          /> */}

            {/* <Card
              link="/clarorio/recursos"
              clientEvent="Teatro Claro Rio de Janeiro"
              eventName="Programação 100% Acessível"
              eventDate="12/09/2023"
              eventHour="20:00"
              ad
              subtitle
          /> */}

          {/* <Card
              link="/teatroclarosp/recursos"
              clientEvent="Teatro Claro São Paulo"
              eventName="Programação 100% Acessível"
              eventDate=""
              eventHour=""
              ad
              subtitle
          /> */}

              {/* <Card
                link="/onovoabelaeafera/recursos"
                clientEvent="Opus Entretenimento "
                eventName="Musical - O Novo A Bela e a Fera"
                eventDate="13/10/2023"
                eventHour="15:00"
                ad
              /> */}
            {/* <Card
              link="/umanoitenabroadway/recursos"
              clientEvent="IMM"
              eventName="Musical - Uma Noite Na Broadway"
              eventDate="10/10 a 08/11"
              eventHour=""
              ad 
              libras
              subtitle
            /> */}

            <Card
              link="/claro/recursos"
              clientEvent="Claro"
              eventName="Eventos esportivos"
              eventDate="26/07 a 11/08/24"
              eventHour=""
              ad 
              libras
              subtitle
            />
            {/* https://www.streamtext.net/player?event=LegendaBeplay */}

              {/* <Card
                link="/matilda/recursos"
                clientEvent="Teatro Claro"
                eventName="Matilda - O Musical"
                eventDate="Todas as datas"
                eventHour=""
                ad
                libras
                subtitle
              /> */}

            {/* <Card
              link="/umalindamulheromusical/recursos"
              clientEvent="IMM e EGG Entretenimento"
              eventName="Uma Linda Mulher - O Musical"
              eventDate="Todas as sessões"
              eventHour=""
              ad
            
              libras
            />  */}





            {/* <Card
              link="/80adecadadovaletudo/recursos"
              clientEvent="Brain +"
              eventName='Musical "80 A DÉCADA DO VALE TUDO" - SÃO PAULO'
              eventDate="Todas as sessões"
              eventHour="16:00"
              ad
              subtitle
            />  */}

          {/* <Card
              link="/bachianas/recursos"
              clientEvent="Fundação Bachiana"
              eventName="Concerto Maestro João Carlos Martins"
              eventDate="temporada 2023"
              eventHour="20:00"
              ad
              subtitle
            />  */}



            <Card
              link="/beplay/recursos"
              clientEvent="Beplay"
              eventName="Evento Beplay"
              eventDate="Data"
              eventHour="Hora"
              ad
              subtitle
              translate
              libras
              ad2
            /> 

 



  
          </div>
        </main>
        <div className="back">
          <Link to="/">
            <img src={leftArrow} alt="Voltar" />
            {t("btn-back")}
          </Link>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Client;
