import styled from "styled-components";
import {Link} from 'react-router-dom'
import media from 'styled-media-query'
import bgTransmissoes from '../../assets/bg_transmissoes.webp'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 113rem;
  margin: 0 auto;
  position: relative;
  scroll-behavior: smooth;
  ${media.lessThan('large')`

    width: 100%;

`}

& #__ba_launchpad {
    position: absolute;
    left: 0;
    top: 0;
}
`;

export const HeaderContainer = styled.div`
    width: 100%;
`

export const HeaderImg = styled.img`
    width: 100%;
`

export const HeadingContainer = styled.div`
    /* margin-bottom: 2rem;
    padding-right: 12rem; */
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    /* padding: 0 0 0 10rem; */
    margin-bottom: 2rem;
    ${media.lessThan('large')`

        width: 80%;
        margin: 2rem 0 0 0;
        padding: 0;
    `}
`

export const Heading = styled.h1`
    font-family: 'AMX';
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;
    margin-top: 4rem;
    padding: 0;
    color: #343131;
    

    ${media.lessThan('large')`
    font-size: 1.188rem;
    line-height: 1.5rem;
    margin: 0 0 1rem 0;
    width: 100%;

   
    `}

`

export const ContainerHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    width:100%;

    ${media.lessThan('medium')`
    margin-bottom: 2rem;
    `}

`

export const SecondHeading = styled.h2`
    font-family: 'AMX';
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;
    margin-top: 4rem;
    padding: 0;
    color: #fff;
    text-transform: uppercase;
    text-align: center;

    ${media.lessThan('medium')`
        font-size: 1.25rem;
        line-height: 1.5rem;
    `}
    

`
export const Subtitle = styled.p`
    font-family: 'AMX';
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #343131;
    margin: 2rem 0;

    ${media.lessThan('medium')`
        font-size: 1rem;
        padding: 1rem;
    `}

    &:focus{
        border: 1px solid #000;
    }
`




export const ButtonContainer = styled.section`
    display: flex;
    align-items:center;
    justify-content: center;
    /* padding: 1rem; */
    margin-bottom: 2rem;
    width: 100%;
    ${media.lessThan('large')`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 90%;
    `}
`

export const ButtonWrapper = styled.div`
     width: 18.75rem;
    padding: 0 1rem;
    ${media.lessThan('large')`
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        padding: 1rem 0;
        width: 80%;
    `}


 
` 

export const Buttons = styled.img`
    width: 18.75rem;

    ${media.lessThan('large')`
        width: 80%;
    `}
`

export const TransmissaoContainer = styled.section`
    background-image: url(${bgTransmissoes});

    /* height: 59.375rem; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.lessThan('medium')`

        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
       
    `}
`



export const CardTransmissaoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lessThan('medium') `
        flex-direction: column;
        `}
`

export const CardTransmissao = styled.img`
    width: 20.625rem;
    height: 13.125rem;
    margin: 2rem 1rem;

    ${media.lessThan('medium') `
            width:80%;

        `}
`

export const ParceiraContainer = styled.div`
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    ${media.lessThan('medium')`
        flex-direction: column;
        justify-content: center;
    `}
`

export const ParceriaText = styled.p`
    font-family: 'AMX';
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    margin-right: 2rem;
`

export const ParceriaLogo = styled.img`
    width: 14.75rem;
    height: 4.25rem;

    ${media.lessThan('medium')`
        margin-top: 1.5rem;
    `}

`

export const FooterContainer = styled.div`
    background-color:#e3262e;
    width: 100%;
    height: 11.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.lessThan('medium')`
        flex-direction: column;
        height: auto;
        justify-content: center;
    `}

`

export const Footer = styled.footer`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.lessThan('medium')`
        flex-direction: column;
        justify-content: center;
    `}
`

export const SocialMediaWrapper = styled.div`
    ${media.lessThan('medium')`
        display: flex;
        flex-direction: column;
        justify-content: center;
    `}
`

export const List = styled.ul`
    list-style-type: none;
    margin: 2rem 0;
    padding: 0;
    display: flex;
    ${media.lessThan('medium')`
        justify-content: center;
        align-items: center;
    `}
`

export const ListItem = styled.li`
        padding-left: 2rem;

        &:not(first-child){
            ${media.lessThan('medium')`
            padding: 0 0.5rem;
    `}     
        }


`

export const ListItemLink = styled.a`
     text-decoration: none;
     cursor: pointer;

`

export const SocialImg = styled.img`
    width: 2.5rem;
    ${media.lessThan('medium')`
        width: 2rem;
    `}
`

export const Rights = styled.div``
export const RightrText = styled.p`
    font-family: 'AMX';
    font-weight: normal;
    font-size: 30px;
    color: #fff;

    ${media.lessThan('medium')`
        font-size: 0.75rem;
    `}
`

export const ClaroLogoContainer = styled.div`
    padding-right: 2rem;
    ${media.lessThan('medium')`
    padding: 1rem 0;
    `}
`

export const ClaroLogo = styled.img`
    /* width: 10.875rem;
    height: 6.5rem;  */
`

export const LinkEvent = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ConvocacaoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${media.lessThan('medium')`
    flex-direction: column;
    `}
`

export const ConvocacaoWrapper = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
    width: 100%;

    ${media.lessThan('medium')`
        margin-bottom: 2rem;
    `}
`

export const ListConvocacao = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;

    ${media.lessThan('medium')`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    `}
`

export const BannerConvocadas = styled.div`
    width: 100%;
    margin-right: 2rem;

    ${media.lessThan('medium')`
    margin-right: 0;
    `}
    & img{
        object-fit: cover;
    }
`

export const HeadingConvocacao = styled.h2`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #121212;
    margin-left: 2rem;
`

export const TelegramWrapper = styled.div`
  width: 90%;
  height: 15rem;
  border: solid 1px #d9d9d9;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem 0;

  ${media.lessThan('medium')`
    width: 100%;
  `}

  & p{
    font-family: AMX;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #343131;

  &:focus{
        border: 1px solid #000;
    }

  ${media.lessThan('medium')`
  font-size: 1.5rem;
  `}
  }

`

export const PlayerList = styled.ul`
    margin: 0 0;
    padding: 1rem;

    ${media.lessThan('medium')`
        padding: 1rem 0;
  `}
`

export const PlayerListItem = styled.li`
    color: #121212;
    font-family: AMX;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
  list-style-type: none;
`