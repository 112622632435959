import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import AgoraRTC from "agora-rtc-sdk";
import { api } from '../../../services/axios';
import { useHistory } from "react-router-dom";
import { credentials } from "../../../auth/credentials";
import { auth } from "../../../auth/broadcaster";
import MenuSystem from "../../../layout/Menu-system";
import { Container, ListCameraContainer,ListCamera, ListCameraItem } from "./styles";
import { SystemButton } from "../../../components/buttons/styles";
import usersLogo from "../../../assets/icon-users.svg";
import HostLogo from "../../../assets/icon-hosts.svg";
import {Gear} from 'phosphor-react'
import AgoraRTC from 'agora-rtc-sdk-ng'

const HostLibras = () => {

  const { t } = useTranslation();
  const history = useHistory();

  if (localStorage.getItem("eventKey") !== credentials.broadcasterPreview) {
    history.push("/organizador/eventos-disponiveis");
  }

  const [isStart, setIsStart] = useState(false);
  const [localStream, setLocalStream] = useState({});
  const [isMute, setIsMute] = useState(false);
  const [userCount, setUserCount] = useState("00");
  const [hostCount, setHostCount] = useState("00");
  const [showCamera, setShowCamera] = useState(false);
  const [devices, setDevices] = useState({cameras: [], mics: []});
  const [localStreams, setLocalStreams] = useState({
    uid: "",
    camera: {
      camId: "",
      micId: "",
      stream: {},
    },
  })



  const appId = auth.appId;
  const channel = auth.channelLibras3;


  const showListCamera = () => {
    setShowCamera(!showCamera)
  }


  const handleError = (err) => {
    console.log("Error: ", err);
  };

  const toggleVideo = (stream) => {
    if (isMute) {
      setIsMute(false);
      stream.unmuteVideo();
    } else {
      setIsMute(true);
      stream.muteVideo();
    }
  };
  const config = {
    mode: "live",
    codec: "vp9",
  };

  const client = AgoraRTC.createClient(config);

  function getCameraDevices() {
    console.log("Checking for Camera Devices.....")
    client.getCameras (function(cameras) {
      setDevices({...devices, cameras: cameras}) // store cameras array
      cameras.forEach(function(camera, i){

        var deviceId = camera.deviceId;
        if(i === 0 && localStreams.camera.camId === ''){
          setLocalStreams({...localStreams, camera: { camId: deviceId}})
          // localStreams.camera.camId = deviceId;

        }
       
      });

    });
  }

  

  const changeStreamSource = (deviceIndex, deviceType) => {
    console.log('Switching stream sources for: ' + deviceType);
    var deviceId;
    // var existingStream = false;
    
    if (deviceType === "video") {
      deviceId = devices.cameras[deviceIndex].deviceId

    }

    localStream.switchDevice(deviceType, deviceId, function(){
      console.log('successfully switched to new device with id: ' + JSON.stringify(deviceId));
      // set the active device ids
      if(deviceType === "audio") {
        setLocalStreams({...localStreams, cameras: {micId: deviceId}})
        // localStreams.camera.micId = deviceId;
      } else if (deviceType === "video") {
        setLocalStreams({...localStreams, camera: {camId: deviceId}})
        // localStreams.camera.camId = deviceId;
      } else {
        console.log("unable to determine deviceType: " + deviceType);
      }
    }, function(){
      console.log('failed to switch to new device with id: ' + JSON.stringify(deviceId));
    });

    showListCamera()
  }

  const handleStartHostLibras = async () => {
    if (isStart === false) {
      setIsStart(true);
      
      // client.init(
      //   appId,
      //   () => {
      //     console.log("Client Initialized");
      //     joinChannel();
      //   },
      //   handleError
      // );

      try {
        await client.join(appId, channel, null, null)
        console.log('Join Success')
      }catch(e){
        console.log('Join failed', e)
      }

      client.on("user-published", async (remoteUser, mediaType) => {
        await client.subscribe(remoteUser, mediaType);
        if (mediaType === "video") {
          console.log("subscribe video success");
          remoteUser.videoTrack.play("play");
        }

      });

      client.on("connection-state-change", (curState, prevState) => {
        console.log("current", curState, "prev", prevState);
      });
      

      // client callbacks
      // client.on("stream-published", (evt) => {
      //   console.log("Publish local stream successfully");
      // });

      // when a remote stream is added
      // client.on("stream-added", (evt) => {
      //   console.log("new stream added: " + evt.stream.getId());
      // });

      //live transcoding events..
      // client.on("liveStreamingStarted", (evt) => {
      //   console.log("Live streaming started");
      // });

      // client.on("liveStreamingFailed", (evt) => {
      //   console.log("Live streaming failed");
      // });

      // client.on("liveStreamingStopped", (evt) => {
      //   console.log("Live streaming stopped");
      // });

      // client.on("liveTranscodingUpdated", (evt) => {
      //   console.log("Live streaming updated");
      // });

      // ingested live stream
      // client.on("streamInjectedStatus", (evt) => {
      //   console.log("Injected Steram Status Updated");
      //   console.log(JSON.stringify(evt));
      // });

      // // when a remote stream leaves the channel
      // client.on("peer-leave", (evt) => {
      //   console.log(
      //     "Remote stream has left the channel: " + evt.stream.getId()
      //   );
      // });

      // const localAudio = await AgoraRTC.createMicrophoneAudioTrack();
      const localVideo = await AgoraRTC.createCameraVideoTrack();

      localVideo.play('play')

      try {
        // Remove this line if the channel profile is not live broadcast.
        await client.setClientRole("host");
        await client.publish([localVideo]);
        console.log("publish success");
      } catch (e) {
        console.log("publish failed", e);
      }

      // try{
      //   await client.setClientRole('host')
      //   await client.publish([localStreams.camera])
      // }catch(e){

      // }

      // function joinChannel() {
      //   client.setClientRole(
      //     "host",
      //     () => {
      //       console.log("Client is a host");
      //     },
      //     handleError
      //   );

      //   client.join(
      //     null,
      //     channel,
      //     null,
      //     (uid) => {
      //       createCameraStream(uid, {});
      //       localStreams.uid = uid;
      //       console.log("User " + uid + " joined channel successfully");
      //     },
      //     handleError
      //   );

      //   setInterval(() => {
      //     client.getSessionStats((stats) => {
      //       setHostCount(stats.UserCount);
          
      //     });
      //   }, 5000);

      //   client.enableDualStream(() => {
      //     console.log("Dual stream is working");
      //   }, handleError);
      // }

      // function createCameraStream(uid) {
      //   const localStream = AgoraRTC.createStream({
      //     streamID: uid,
      //     audio: false,
      //     video: true,
      //     screen: false,
      //   });

      //   setLocalStream(localStream);

      //   // The user has granted access to the camera and mic.
      //   localStream.on("accessAllowed", () => {
      //     if (devices.cameras.length === 0 && devices.mics.length === 0) {
      //       console.log("[DEBUG] : checking for cameras & mics");
      //       getCameraDevices();
      //     }
      //     console.log("accessAllowed");
      //   });
      //   // The user has denied access to the camera and mic.
      //   localStream.on("accessDenied", () => {
      //     console.log("accessDenied");
      //   });

      //   // localStream.init(() => {
      //   //   console.log("getUserMedia successfully");
      //   //   localStream.play("play");

      //   //   client.publish(localStream, handleError);
      //   // }, handleError);



      // }
      // setInterval(()=>{
      //   api.get(`v1/channel/user/${appId}/${channel}`).then((response) =>{
      //     setUserCount(response.data.data.audience_total)
      //   })
      // }, 10000)

      
    } else {
      setIsStart(false);
      setShowCamera(false)
     
      window.location.reload();
    }
  };
  return (
    <Container>
          

          
          
              <ListCameraContainer show={showCamera}>
                <ListCamera >
                  
                  {devices.cameras.map((camera, index) => {
                    return (             
                          <ListCameraItem value={index} key={index} onClick={() => changeStreamSource(index, "video")}>{camera.label}</ListCameraItem>
                    )
                    
                  })}
                </ListCamera>
              </ListCameraContainer>
         
      <MenuSystem url="/organizador/eventos/preview/escolher-recurso" />
      <div className="systemTransmission">


     
        <div className="systemInfo">


          {!isStart && (
            <SystemButton onClick={() => handleStartHostLibras()}>
              
              {t("client-btn-start-transmission")}
            </SystemButton>
            
          )}

          {isStart && (
            <>
             
              <div className="btn-libras-transmission">
              <Gear size={32} color="#fffafa" className='settings' onClick={showListCamera} />
                <SystemButton
                  style={{ backgroundColor: "#26f7fd" }}
                  onClick={() => handleStartHostLibras()}
                >
                  {t("client-btn-finish-transmission")}
                </SystemButton>

                {/* <SystemButton
                  style={{
                    marginLeft: "1rem",
                    backgroundColor: isMute ? "#26f7fd" : "",
                  }}
                  onClick={() => toggleVideo(localStream)}
                >
                  {isMute ? t("client-btn-unmute-transmission") : t("client-btn-mute-transmission")}
                </SystemButton> */}
              </div>


              <div className="transmission-users-info">
                  <div className="online-users">
                    <img src={usersLogo} alt="Logo users" />
                    <p className="p">{`${userCount} ${t("client-transmission-ad-online")}`}</p>
                  </div>
                  <div className="online-users">
                    <img src={HostLogo} alt="Logo hosts" />

                    <p className="p">{`${hostCount} ${t(
                      "host-transmission-ad-online"
                    )}`}</p>
                  </div>
                </div>
            </>
          )}
        </div>
      </div>
      <div id="play"></div>
    </Container>
  );
};
export default HostLibras;
