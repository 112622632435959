import styled from "styled-components";
import { device } from "../../../style/responsive";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--blue-dark);
  position: relative;

  .event {
    display: grid;
    grid-template-columns: 40% 1fr;
    width: 75%;
    grid-gap: 4rem;

    @media only screen and ${device.tabLand} {
      grid-template-columns: 1fr;
      width: 100%;
      grid-gap: 1rem;
    }

    &__top {
      margin: 5rem 2rem 0 2rem;
      color: var(--white);

      @media only screen and ${device.tabLand} {
        margin: 1rem 0.5rem 0 0.5rem;
      }

      h2 {
        font-family: Heebo;
        font-size: 4rem;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: var(--white);

        @media only screen and ${device.tabLand} {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }

    &__available {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin: 5rem 0 0 4rem;
      color: var(--blue-light);

      @media only screen and ${device.tabLand} {
        margin: 0 0.5rem;
      }

      h4 {
        font-family: Heebo;
        font-size: 0.8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--blue-light);

        @media only screen and ${device.tabPort} {
          margin-left: 1rem;
        }
      }
    }

    .resourcesTitle {
      margin: 4rem 0 0 0;
      h4 {
        font-family: Heebo;
        font-size: .8rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--blue-light);
        margin-left: 0;
      }
    }

    .resources {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      @media only screen and ${device.tabLand} {
        justify-content: space-around;
        align-items: flex-start;
        margin-bottom: 2rem;
      }

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2rem 0.5rem 0 0;
        width: 7.25rem;
        height: 6.25rem;
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.5rem;
        border-radius: 8px;
        background-color: var(--yellow-light);
        color: var(--blue-dark);

        @media only screen and ${device.tabLand} {
          flex-wrap: wrap;
          width: 9rem;
          height: 7.5rem;
          margin: 0.5rem 0.5rem 0 0;
        }
      }

      &__card:last-child {
        margin: 2rem 0rem 0 0;

        @media only screen and ${device.tabLand} {
          margin: 0.5rem 0.5rem 0 0;
        }
      }

      a {
        text-decoration: none;
        color: var(--blue-dark);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .back {
    display: flex;
    justify-content: start;
    align-self: start;
    font-size: 1rem;
    border: 1px solid #fff;
    width: 5rem;
    padding: 1rem;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 15%;

    @media only screen and ${device.tabLand} {
      justify-content: start;
      align-self: start;
      margin: 2rem 0 2rem 1rem;
      /* margin-left: 1rem;
      margin-bottom: 2rem; */
      padding: 0.5rem;
      position: relative;
      top: 0;
      left: 0;
    }

    a {
      display: flex;
      color: var(--white);
      text-decoration: none;

      img {
        margin-right: 0.5rem;
      }
    }

    @media;
  }
`;
