import React, { useState} from "react";
import AgoraRTC from "agora-rtc-sdk";
import AgoraRTM from 'agora-rtm-sdk';
import { useTranslation } from "react-i18next";
import { auth } from "../../../auth/broadcaster";
import MenuSystem from "../../../layout/Menu-system";
import { Container } from "./styles";
import { SystemButton } from "../../../components/buttons/styles";
import adLogo from "../../../assets/icon-ad.svg";
import live from "../../../assets/icon-live.svg";
import comiteLogo from "../../../assets/ipc-logo.jpg";
import claroLogo from "../../../assets/claro.png";
import sportvLogo from "../../../assets/AssinaParalimpiadasSporTV_P.png";
import logoBeplay from "../../../assets/logo2.png";

const ClientAd = () => {

  const { t } = useTranslation();
  const [start, setStart] = useState(false);
  const [uid, setUid] = useState("");
  const [activate, setActivate] = useState({
    backgroundColor: "",
    border: "",
    color: "",
    title: t("client-btn-start-transmission"),
  });


  const appId = auth.appId;
  const channel = auth.channelAdSecondary;
  const config = {
    mode: "live",
    codec: "h264",
  };

  const handleError = (err) => {
    console.log("Error: ", err);
  };

  const client = AgoraRTC.createClient(config);
  const clientRTM = AgoraRTM.createInstance(appId);

  const handleCountUser = () =>{
    clientRTM
    .getChannelMemberCount()
    .then(() => {
      console.log("USER COUNT HERE");
    })
    .catch((err) => {
      console.log(err);
    });
  }



  const handleStartClient = () => {
    if (!start) {
      setStart(true);
      setActivate({
        backgroundColor: "transparent",
        border: "1px solid #fff",
        color: "#fff",
        title: t("client-btn-finish-transmission"),
      });

      client.init(
        appId,
        () => {
          console.log("Audience initialized");
          joinChannel();
        },
        handleError
      );

      client.on("stream-published", function (evt) {
        console.log("Publish local stream successfully");
      });

      client.on("stream-added", function (evt) {
        let stream = evt.stream;
        let streamId = stream.getId();
        console.log("New stream added: " + streamId);
        console.log("Subscribing to remote stream:" + streamId);
        // Subscribe to the stream.
        client.subscribe(stream, handleError);
      });

      client.on("stream-removed", function (evt) {
        let stream = evt.stream;
        stream.stop(); // stop the stream
        stream.close(); // clean up and close the camera stream
        console.log("Remote stream is removed " + stream.getId());
      });

      client.on("stream-subscribed", function (evt) {
        let remoteStream = evt.stream;
        remoteStream.play("play");
        console.log(
          "Successfully subscribed to remote stream: " + remoteStream.getId()
        );
      });

      client.on("peer-leave", function (evt) {
        console.log("Remote stream has left the channel: " + evt.uid);
        evt.stream.stop(); // stop the stream
      });

      function joinChannel() {
        client.setClientRole(
          "audience",
          () => {
            console.log("Client set as a audience");
          },
          handleError
        );

        client.join(
          null,
          channel,
          null,
          (uid) => {
            setUid(uid);
            console.log("User " + uid + " join channel successfully");
          },
          handleError
        );
      }

      clientRTM
        .login({ uid: channel, token: appId })
        .then(() => {
          console.log("Logado com sucesso");
        })
        .catch((err) => {
          console.log(err);
        });

        const channelRTM = clientRTM.createChannel("audiodescricao");

        channelRTM.join().then(() =>{
          clientRTM.getChannelAttributes(channel).then(channelAttributes =>{
            console.log('CHANNEL INFO: ', channelAttributes)
          }).catch(err =>{
            console.log(err);
          });
        }).catch(err =>{
          console.log(err);
        })


 
    } else {
      setActivate({
        backgroundColor: "",
        border: "",
        color: "",
        title: t("client-btn-start-transmission"),
      });
      setStart(false);
      window.location.reload();
    }
  };
  return (
    
 <>
    <Container>
    {/* <div className="logos-bar">
         <div className="logos-img-container">
           <img className="logos-img" src={logoBeplay} alt="Logo Beplay"></img>
           <img className="logos-img" src={claroLogo} alt="Logo Claro"></img>
           <img className="logos-img" src={sportvLogo} alt="Logo Sportv"></img>
           <img className="logos-img comiteLogo" src={comiteLogo} alt="Logo Comite olímpico"></img>
         </div>
       </div> */}
      <MenuSystem url="/bachianas/recursos" />
      <div className="systemTransmission">
        <div className="systemInfo">
          {!start && (
            <>
              <img src={adLogo} alt="ícone da audiodescrição" />
              <h3>{t("audiodescription-title")}</h3>
              <p>{t("client-ad-text1")}</p>
              <p>
              {t("client-ad-text2")}
              </p>
            </>
          )}

          {start && (
            <>
              <div className="live">
                <p className="live-paragraph">{t("client-live-text")}</p>
                <img src={live} alt="ícone ao vivo" />
              </div>
            </>
          )}
          <SystemButton style={activate} onClick={() => handleStartClient()}>
            {activate.title}
          </SystemButton>
        </div>
      </div>
      
      <div id="play"></div>

    </Container>
 

</>
  );
};
export default ClientAd;
