import { createGlobalStyle } from "styled-components";
import { device } from "../style/responsive";

export default createGlobalStyle`

:root {
  --yellow-light: #e6ff5f;
  --blue-dark: #1751b9;
  --grey: #f2f2f2;
  --blue-light: #26f7fd;
  --black: #000;
  --white: #fff;
}

* {
    &::after,
    &::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
        }
    }

    #root{
        width: 100%;
    }

body{
    font-family: 'Heebo', sans-serif;  
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
        scroll-behavior: smooth;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
        padding:0 0;
}

h1,h2,h3,h4,p{
    margin: 0;
    padding: 0;
}

h1{
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: bold;
    padding: 2rem 0;

    @media only screen and ${device.tabPort} {
      font-size: 2.4rem;
}
`;
