import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";

const Footer = () => {
   const { t } = useTranslation();
  return (
    <Container>
      <p>{t("contact-us-footer")}</p>
    </Container>
  );
};

export default Footer;
