import React, { createContext, useState, useContext } from "react";

const UserCountContext = createContext();

export default function UserCountProvider({ children }) {
  const [userCount, setUserCount] = useState("00");
  return (
    <UserCountContext.Provider
      value={{ userCount, setUserCount }}
    >
        {children}
    </UserCountContext.Provider>
  );
}

export function useCountUser() {
  const context = useContext(UserCountContext);
  const { userCount, setUserCount } = context;
  return { userCount, setUserCount };
}
