import React from "react";
import AgoraRTC from "agora-rtc-sdk";
import { auth } from "../../../auth/broadcaster";
import { Container } from "./styles";
import Menu from "../../../layout/Menu-system";
import comiteLogo from "../../../assets/ipc-logo.png";
import claroLogo from "../../../assets/claro.png";
import sportvLogo from "../../../assets/AssinaParalimpiadasSporTV_P.png";
import logoBeplay from "../../../assets/logo2.png";

const ClientLibrasContent = () => {

    const handleError = (err) =>{
        console.log('Error: ', err)
    }

    const config = {
        mode: "live",
        codec: "h264",
      };

    const client = AgoraRTC.createClient(config);

    client.init(
        auth.appIdLibrasDemo,
        () =>{
            console.log('Start as audience');
            joinChannel();
        },
        handleError
    );

    client.on("stream-published", (evt)=> {
        console.log("Publish local stream successfully");
      });

        // connect remote streams
  client.on("stream-added", (evt) => {
    let stream = evt.stream;
    let streamId = stream.getId();
    console.log("New stream added: " + streamId);
    console.log("Subscribing to remote stream:" + streamId);
    // Subscribe to the stream.
    client.subscribe(stream, handleError);
  });

  client.on("stream-removed", (evt) => {
    let stream = evt.stream;
    stream.stop(); // stop the stream
    stream.close(); // clean up and close the camera stream
    console.log("Remote stream is removed " + stream.getId());
  });

  client.on("stream-subscribed", (evt) => {
    let remoteStream = evt.stream;
    remoteStream.play("play");
    console.log(
      "Successfully subscribed to remote stream: " + remoteStream.getId()
    );
  });

  // remove the remote-container when a user leaves the channel
  client.on("peer-leave", (evt) => {
    console.log("Remote stream has left the channel: " + evt.uid);
    evt.stream.stop(); // stop the stream
  });

  // ingested live stream
  client.on("streamInjectedStatus", (evt) => {
    console.log("Injected Steram Status Updated");
    // evt.stream.play('full-screen-video');
    console.log(JSON.stringify(evt));
  });

  function joinChannel(){
      client.setClientRole(
          "audience",
          () =>{
            console.log("Client role set to audience");
          },
          handleError
      )
  }

  client.join(
      null,
      auth.channelLibrasDemo,
      null,
      (uid) =>{
        console.log("User " + uid + " join channel successfully");
      },
      handleError
  )

  return (
    <Container>
      {/* <Menu isButton /> */}

      <div className="play" id="play"></div>
    </Container>
  );
};

export default ClientLibrasContent;
