import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationPtBr from "./locales/pt-br/translation.json";
import translationEn from "./locales/en/translation.json";
import translationEs from "./locales/es/translation.json";

i18n.use(LanguageDetector).init({
  resources: {
    ptBR: { translations: translationPtBr },
    en: { translations: translationEn },
    es: { translations: translationEs },
  },

  fallbackLng: "ptBR",
  debug: false,
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
