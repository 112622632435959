import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--black);
  height: 100vh;
  position: relative;

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      margin-bottom: 2rem;
    }
  }
`;
