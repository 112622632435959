import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Contact } from "./styles";
import { ContactButton, SocialButton } from "../../components/buttons/styles";
import peopleIllustration from "../../assets/illustration-people.svg";
import facebookIcon from "../../assets/facebook.svg";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Contact>
        <h4>{t("contact-us-title")}</h4>
        <div className="contact-section">
          <div className="heading-contact">
            <h2>{t("contact-us-text")}</h2>
            <div className="btnContact">
              <ContactButton href="mailto:contato@beplay.com.br">
              {t("contact-us-btn")}
              </ContactButton>
            </div>
            <div className="social">
              <ul className="social__list">
                <li className="social__item">
                  <SocialButton
                    className="social__link"
                    href="https://www.facebook.com/flixplay.tv"
                  >
                    <img
                      src={facebookIcon}
                      title="ícone do facebook"
                      alt="ícone do facebook"
                    />
                  </SocialButton>
                </li>
              </ul>
            </div>
            <div className="copyright">
              <small>{t("contact-us-footer")}</small>
            </div>
          </div>
          <div className="contact-illustration">
            <img
              src={peopleIllustration}
              alt={t("alt-illustration3")}

            />
          </div>
        </div>
      </Contact>
    </Container>
  );
};

export default ContactUs;
