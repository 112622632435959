/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef} from "react";
import * as S from './styles'
import video from '../../assets/teste.mp4'

const Dev = () => {
    const idx = useRef(undefined);

    async function callPip(){
        const frame = document.getElementById('frame');
        const videoElement = frame.children[0].querySelector("video");
        console.log(videoElement);
    }

    useEffect( () => {
        //  callPip()
    }, [])
    

  return (
<S.Container>
    <S.Iframe src={video} autoPlay controls>

    </S.Iframe>


    <S.IframeVideo ref={idx} id="frame" src="https://beplay.com.br/preview/libras">
    {/* <>
      {React.Children.map((element, idx) => {
        return React.cloneElement(element, { ref: idx.current });
      })}
    </> */}
    </S.IframeVideo>
    {/* <button onClick={callPip}>Call Pip</button> */}
</S.Container>

  );
};

export default Dev;