import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Nav } from "./styles";
import Logo from "../../components/Logo";
import arrowLeft from "../../assets/icon-arrow-left.svg";

const MenuSystem = ({ url, externalUrl, isButton, action }) => {

  const {t} = useTranslation();

  return (
    <>
      <Nav>
        <Logo />
        <div className="back">
          {isButton &&(
          <button onClick={action}>
          <img src={arrowLeft} alt="Botão voltar" />
            {t("btn-back")}</button>
          )}
          {url && (
          <Link to={url}>
          <img src={arrowLeft} alt="Botão voltar" />
          {t("btn-back")}
        </Link>
          )}

          {externalUrl && (
            <a href={externalUrl}>
              <img src={arrowLeft} alt="Botão voltar" />
              {t("btn-back")}
            </a>
          )}

        </div>
      </Nav>
    </>
  );
};

export default MenuSystem;
