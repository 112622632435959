const size = {
  smallPhone: "23.43em",
  phone: "37.5em",
  tabPort: "56.25em",
  tabLand: "75em",
  bigDesktop: "112.5em",
};

export const device = {
  smallPhone: `(max-width: ${size.smallPhone})`,
  phone: `(max-width: ${size.phone})`,
  tabPort: `(max-width: ${size.tabPort})`,
  tabLand: `(max-width: ${size.tabLand})`,
  bigDesktop: `(min-width: ${size.bigDesktop})`,
};
