import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import FlagProvider from "./context/flag";
import UserCountProvider from "./context/userCount";
import UserLinkProvider from './context/lock';

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
          <UserLinkProvider>
      <FlagProvider>
        <UserCountProvider>
          <App />
        </UserCountProvider>
      </FlagProvider>
          </UserLinkProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
