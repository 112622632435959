import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AgoraRTC from "agora-rtc-sdk";
import { api } from "../../../services/axios";
import { useCountUser } from "../../../context/userCount";
import { auth } from "../../../auth/broadcaster";
import {credentials} from '../../../auth/credentials';
import MenuSystem from "../../../layout/Menu-system";
import { Container } from "./styles";
import { SystemButton } from "../../../components/buttons/styles";
import adLogo from "../../../assets/icon-ad.svg";
import translateLogo from "../../../assets/icon-translation.svg"
import micOn from "../../../assets/bt-mic-on.svg";
import micOff from "../../../assets/bt-mic-off.svg";
import live from "../../../assets/icon-live.svg";
import usersLogo from "../../../assets/icon-users.svg";
import HostLogo from "../../../assets/icon-hosts.svg";
import TranslationLogo from "../../../assets/icon-translation.svg";



const ClientAd = () => {
  
  const { t } = useTranslation();
  const history = useHistory();
  const key = localStorage.getItem("eventKey");
  if(key !== credentials.broadcasterButanta){
    history.push("/organizador/eventos-disponiveis")
  }
  const [start, setStart] = useState(false);
  const [mic, setMic] = useState(false);
  const [statusMic, setStatusMic] = useState("Microfone ligado");
  const [localStream, setLocalStream] = useState({});
  const [stats, setStats] = useState({});
  const [hostCount, setHostCount] = useState("00");
  const [activate, setActivate] = useState({
    backgroundColor: "",
    border: "",
    color: "",
    title: t("client-btn-start-transmission"),
  });
  const { userCount, setUserCount } = useCountUser();

  const appId = auth.appId;
  const channel = auth.channelButanta;

  const handleBackButton = () => {
    !start ? history.goBack() : alert('Finalize a transmissão antes de sair da página')
  }
  
  const localStreams = {
    uid: "",
    camera: {
      camId: "",
      micId: "",
      stream: {},
    },
  };

  const devices = {
    cameras: [],
    mics: [],
  };

  const config = {
    mode: "live",
    codec: "h264",
  };

  const handleError = (err) => {
    console.log("Error: ", err);
  };


  const client = AgoraRTC.createClient(config);

  const closeConnection = () => {
    window.location.reload();
  };
  const toggleMuteAudio = (stream) => {
    if (mic === false) {
      setMic(true);
      stream.unmuteAudio();
      setStatusMic("Microfone ligado");
    } else {
      setMic(false);
      stream.muteAudio();
      setStatusMic("Microfone desligado");
    }
  };

  const handleStartHost = () => {
    if (start === false) {
      setActivate({
        backgroundColor: "transparent",
        border: "1px solid #fff",
        color: "#fff",
        title: t("client-btn-finish-transmission"),
      });
      setStart(true);
      setMic(true);

      client.init(
        appId,
        () => {
          console.log("System initialized");
          joinChannel();
        },
        handleError
      );

      client.on("stream-published", (evt) => {
        console.log("EVT: ", evt);
      });

      client.on("mute-audio", (evt) => console.log("mute audio", evt));

      client.on("unmute-audio", (evt) => console.log("unmute audio", evt));

      function joinChannel() {
        client.setClientRole(
          "host",
          () => console.log("User set as a host"),
          handleError
        );

        client.join(
          null,
          channel,
          null,
          (uid) => {
            createCameraStream(uid, {});
            localStreams.uid = uid;
          },
          handleError
        );
      }

      setInterval(() => {
        client.getSessionStats((stats) => {
          setStats(stats);
          setHostCount(stats.UserCount);
        
        });
      }, 3000);

      function createCameraStream(uid) {
        const localStream = AgoraRTC.createStream({
          streamID: uid,
          video: false,
          audio: true,
          screen: false,
        });

        localStream.on("accessAllowed", () => {
          if (devices.cameras.length === 0 && devices.mics.length === 0) {
            console.log("Checking mic and camera");
          }
          console.log("Access allowed!");
        });

        localStream.on("accessDenied", () => handleError);
        localStream.init(() => {
          localStream.play("play");

          client.publish(localStream, handleError);
          setLocalStream(localStream);
        }, handleError);
      }

      setInterval(()=>{
        api.get(`v1/channel/user/${appId}/${channel}`).then((response) =>{
          setUserCount(response.data.data.audience_total)
        })
      }, 10000)
    } else {
      closeConnection(localStream);
      setActivate({
        backgroundColor: "",
        border: "",
        color: "",
        title: "Iniciar transmissão",
      });

      setStart(false);
      setMic(false);
    }
  };
  return (
    <Container>
      <MenuSystem isButton action={handleBackButton} />
      <div className="systemTransmission">
        <div className="systemInfo">
          {!start && (
            <>
              <img src={adLogo} alt="ícone da audiodescrição" />
              <h3>{t("audiodescription-title")}</h3>
         
            </>
          )}

          {start && (
            <>
              <button
                className="mic"
                onClick={() => toggleMuteAudio(localStream)}
              >
                <img
                  src={mic ? micOn : micOff}
                  title={statusMic}
                  alt="ícone microfone"
                />
              </button>

              <div className="live">
                <p className="live-paragraph">
                  {t("broadcast-trad-frances")}
                  
                </p>
                <img src={live} alt="ícone ao vivo" />
              </div>
            </>
          )}
          <SystemButton style={activate} onClick={() => handleStartHost()}>
            {activate.title}
          </SystemButton>
        </div>
      </div>
      {start && (
        <div className="transmission-users-info">
          <div className="online-users">
        <img src={usersLogo} alt="Logo users" />
        <p className="p">{`${userCount} ${t("client-transmission-ad-online")}`}</p>
      </div>
          <div className="online-users">
            <img src={HostLogo} alt="Logo hosts" />

            <p className="p">{`${hostCount} ${t(
              "host-transmission-ad-online"
            )}`}</p>
          </div>
        </div>
      )}

      <div id="play"></div>
    </Container>
  );
};
export default ClientAd;
