import styled from "styled-components";
import {device } from '../../../style/responsive'

export const Container = styled.div`
  background-color: #000;
  display: flex;
  justify-content: start;
  flex-direction: column;
  height: 100vh;
  position: relative;

  .systemTransmission {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .systemInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
      }
    }

    .live {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        .live-paragraph {
          padding-right: 0.5rem;
          font-family: Heebo;
          font-size: 1rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #03ff00;
        }
      }

  
  }

  .logos-bar{
    background: #fcfcfc;
    width: 100%;
    position: absolute;
    bottom: 0;

  }

  ul{
    display: flex;
    justify-content: space-between !important;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #fcfcfc;
    position: absolute;
    bottom: 0;
    width: 100%;

  }

  li {
    display: flex;
    display:-webkit-flex;
    justify-content: center;
    margin-left: 0.5rem;
    width: 100%; 
    height: 3.5rem;
  }

 .img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;
    // height: 64px;
    @media only screen and ${device.phone} {
      width: 2.5rem;
    }

  } 
`;
