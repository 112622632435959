import React from "react";
import { Container } from "./styles";
import Hero from "../../layout/Hero";
import AboutTech from "../../layout/about-tech";
import ListResources from "../../layout/Accessibility";

const Home = () => {


  return (
    <>
      <Container>
        <Hero />
        <AboutTech />
        <ListResources />
      </Container>
    </>
  );
};

export default Home;
