import React from "react";
import { useTranslation } from "react-i18next";
import ContactUs from "../../layout/contact-us";
import { Container, ResourcesItems } from "./styles";
import adIcon from "../../assets/icon-ad.svg";
import librasIcon from "../../assets/icon-hands.svg";
import SubtitleIcon from "../../assets/icon-cc.svg";
import TranslateIcon from "../../assets/icon-translation.svg";

const ListResources = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <ResourcesItems>
        <h4>{t("accessibility-title")}</h4>
        <div className="resources">
          <div className="resources__item">
            <div className="feature">
              <img src={adIcon} alt="Ícone audiodescrição" />
            </div>
            <div className="definition">
              <h3>{t("audiodescription-title")}</h3>
              <p>
              {t("audiodescription-text")}
              </p>
            </div>
          </div>

          <div className="resources__item">
            <div className="feature">
              <img src={librasIcon} alt="Ícone de Libras" />
            </div>
            <div className="definition">
              <h3>{t("libras-title")}</h3>
              <p>
              {t("libras-text")}
              </p>
            </div>
          </div>

          <div className="resources__item">
            <div className="feature">
              <img src={SubtitleIcon} alt="Ícone de legendas" />
            </div>
            <div className="definition">
              <h3>{t("subtitle-title")}</h3>
              <p>
              {t("subtitle-text")}
              </p>
            </div>
          </div>

          <div className="resources__item">
            <div className="feature">
              <img src={TranslateIcon} alt="Ícone de tradução" />
            </div>
            <div className="definition">
              <h3>{t("translate-title")}</h3>
              <p>
              {t("translate-text")}
              </p>
            </div>
          </div>
        </div>
      </ResourcesItems>
      <ContactUs />
    </Container>
  );
};

export default ListResources;
