import styled from "styled-components";
import { device } from "../../style/responsive";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 37.5rem;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Contact = styled.div`
  width: 75%;

  h4 {
    font-size: 1rem;
    color: var(--blue-dark);
    padding: 4rem 0 2rem 0;
  }

  .contact-section {
    display: flex;
    justify-content: space-between;

    @media only screen and ${device.tabPort} {
        display: flex;
        flex-direction:column-reverse;
      }

      .contact-illustration{
    
        
        img{


        @media only screen and ${device.tabLand} {
            width: 20rem;
            height: 12rem;
        }

        @media only screen and ${device.tabPort} {
            width: 15rem;
            height: 10rem;
        }

        @media only screen and ${device.smallPhone} {
          width: 14rem;
            height: 9rem;
  }
        }
      }
  
  }

  .heading-contact {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media only screen and ${device.tabPort} {
      width: 90%;
      }

    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
      color: #000;
    }

    .copyright{
    @media only screen and ${device.tabLand} {
      display:flex;
      justify-content:center;
      align-items: center;
      }
  }
  }
  .social__list {
    display: flex;
    list-style: none;
    padding: 0 0;
    margin: 2rem 0 0.5rem 0;

    .social__item {
      width: 20rem;
      
  }




`;
