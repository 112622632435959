import styled from "styled-components";
import { device } from "../../../style/responsive";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  background-color: var(--blue-dark);
  @media only screen and ${device.tabLand} {
    width: 100%;
    margin: 0;
  }

  .main {
    width: 75%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    @media only screen and ${device.tabLand} {
      width: 90%;
    }
  }

  .heading {
    h2 {
      width: 50%;
      font-family: Heebo;
      font-size: 4rem;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      color: var(--white);
      margin-bottom: 1rem;

      @media only screen and ${device.tabLand} {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.33;
      }
    }

    p {
      font-family: Heebo;
      font-size: 1rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);

      a {
        font-family: Heebo;
        font-size: 0.8rem;
        color: var(--white);
      }
    }
  }

  .choose-event {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 2rem;
    @media only screen and ${device.tabLand} {
      width: 100%;
    }

    .h3 {
      font-family: Heebo;
      font-size: 1.5rem;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);
      margin-bottom: 1rem;
    }

    .label {
      display: block;
      font-family: Heebo;
      font-size: 0.625rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: var(--blue-light);
      margin-bottom: 0.5rem;
    }

    .login {
      width: 34.5rem;
      height: 3.75rem;
      border-radius: 8px;
      border: none;
      padding: 0.2rem 0.5rem;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      @media only screen and ${device.tabLand} {
        width: 95%;
        height: 3.75rem;
        font-size: 1rem;
      }

      &:focus,
      &:active {
        outline: none;
        border: none;
      }
    }

    .input-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      @media only screen and ${device.tabLand} {
        flex-direction: column;
      }

      input {
        width: 16.5rem;
        height: 3.75rem;
        border-radius: 8px;
        border: none;
        padding: 0.2rem 0.5rem;
        font-size: 1.2rem;
        margin-right: 2rem;

        @media only screen and ${device.tabLand} {
          width: 95%;
          height: 3.75rem;
          font-size: 1rem;
        }

        &:focus,
        &:active {
          outline: none;
          border: none;
        }
      }
    }
  }

  .back {
    margin-bottom: 4rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 8px;
      padding: 0.5rem;
      text-decoration: none;

      img {
        margin-right: 0.5rem;
      }
    }
  }

  .alert {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: var(--yellow-light);

      @media only screen and ${device.tabPort} {
        margin-left: 2rem;
        margin-top: 2rem;
        /* margin: 2rem; */
      }
    }
`;
