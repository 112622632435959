import React, { createContext, useState, useContext } from "react";
import brazilFlag from "../assets/brazil.png";
import EnglishFlag from "../assets/english.png";
import SpanishFlag from "../assets/spanish.png";

const FlagContext = createContext();

const i18nextLng = localStorage.getItem("i18nextLng");
const defineDefaultFlag = () =>{
  if(i18nextLng === "en-GB" || i18nextLng === "en"){
    return EnglishFlag;
  }

  if(i18nextLng === "ptBR" || i18nextLng === "pt"){
    return brazilFlag;
  }

  if(i18nextLng === "es"){
    return SpanishFlag;
  }
}

const language = () => {
  switch (i18nextLng) {
    case "ptBR":
      return brazilFlag;
    case "en":
      return EnglishFlag;
    case "en-GB":
      return EnglishFlag;
    case "es":
      return SpanishFlag;
      default:
        return defineDefaultFlag;
  }
};
export default function FlagProvider({ children }) {
  const [flag, setFlag] = useState(language);

  return (
    <FlagContext.Provider
      value={{
        flag,
        setFlag,
      }}
    >
      {children}
    </FlagContext.Provider>
  );
}

export function useFlag() {
  const context = useContext(FlagContext);

  const { flag, setFlag } = context;
  return { flag, setFlag };
}
