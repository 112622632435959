import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../style/responsive";

export const Container = styled(Link)`
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
  width: 100%;
  .card {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    background-color: var(--white);
    box-shadow: 0 4px 2px 0 rgba(12, 14, 168, 0.5);
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.4s;
    width: 100%;
    overflow: hidden;
    

    &:link,
    &:active {
      transform: translateY(3px);
    }

    /* @media only screen and ${device.ultraWide} {
      max-width: 114rem;
      margin: 2rem auto;
    } */

    &__name {

      margin: .5rem 0 .5rem 1rem;

      @media only screen and ${device.tabLand}{
        margin: .5rem 0 .5rem 0;
      }
      .h4 {
        font-size: .625rem;
        font-weight: 600;
        line-height: 0.75rem;
        margin-top: 0.5rem;
        text-transform: uppercase;
        color: var(--blue-dark);
        margin-left: 0;
      }
      @media only screen and ${device.tabLand} {
        margin-left: 1rem;
    }
    }
    &__event-name {
      margin: 0 2rem 1rem 1rem;
      h2 {
        font-family: Heebo;
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: var(--black);
        margin-top: 0.5rem;

      @media only screen and ${device.tabPort} {
        font-size: 1rem;
    }
      }
    }

    &__event-time {
      display: flex;
      margin: .5rem .5rem .5rem 1rem;

      .date {
        display: flex;
        justify-content:center;
  // width: 3.375rem;
  width: 5rem;
  // height: 0.75rem;
  font-family: Heebo;
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--black);
  border: 1px solid #000;
  padding: .5rem;
  border-radius: 100px;
  margin-right: .5rem;
      }

      .hour {
        // width: 5rem;
        width: 15rem;
        // height: 0.75rem;
        height: 1rem;
        font-family: Heebo;
        font-size: 0.625rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: var(--black);
        border: 1px solid #000;
        padding: .5rem;
        border-radius: 100px;
        display:flex;
        justify-content:center;
     }

      .restrict {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        align-items: center;
        padding: .5rem;
        width: 6.93rem;
        margin-left: .5rem;
        font-size: .625rem;
        font-weight: 500;
        border-radius: 100px;
        background-color: var(--blue-light);

      }
    }
    &__resources {
      background-color: var(--grey);
      display: flex;
      height: 3rem;
      justify-content: start;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--blue-dark);
        margin: 1rem .5rem .5rem 1rem;
        font-size: .625rem;
        font-weight: 600;
        text-transform: uppercase;

        /* @media only screen and ${device.tabPort} {
          font-size: 1.6rem;
          margin: 1rem;
        } */
      }

      &__icons {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        align-self: stretch;

        ul {
          display: flex;
          list-style: none;
          padding: 0 0;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: .5rem;
            align-self: stretch;

            img {
              width: 2rem;
              /* @media only screen and ${device.bigDesktop} {
                width: 4.5rem;
              } */
            }
          }
        }
      }
    }
  }
`;
