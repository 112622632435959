import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";
import logo from "../../assets/logo-fix-play.svg";

const Logo = () => {
  const {t} = useTranslation();
  return (
    <>
      <Container>
        <img
          src={logo}
          title="Logo Beplay"
          alt={t("alt-logo")}
        />
      </Container>
    </>
  );
};

export default Logo;
