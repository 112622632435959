import styled from "styled-components";
import brazilFlag from "../../assets/brazil.png";
import EnglishFlag from "../../assets/english.png";
import SpanishFlag from "../../assets/spanish.png";
import { device } from "../../style/responsive";

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  position: relative;
  padding-top: 1rem;
  /* padding: 1rem; */

  @media only screen and ${device.tabPort} {
    width: 100%;
    height: 4rem;
  }

  .chooseLanguage {
    display:flex;
    align-items:center;
    justify-content:center;
    @media only screen and ${device.tabPort} {
      margin-right: 1rem;
    }
  }

  .span {
    flex: 1;
    justify-content: center;
    align-items: center;
    color: var(--white);
    margin-right: 0.5rem;

    @media only screen and ${device.tabLand} {
      display: none;
    }
  }

  .btnMenu {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .dropMenu {
    display: flex;
    background-color: #fafafa;
    border-radius: 8px;
    position: absolute;
    width: 10rem;
    top: 3rem;
    overflow: hidden;
    z-index: 11111;

    @media only screen and ${device.tabPort} {
      top: 4rem;
      right: 2rem;
    }
  }
  .itemMenuBR {
    display: flex;
    justify-content: space-around;
    padding: 0.7rem;
    cursor: pointer;
    border-bottom: 1px solid #cecece;
    position: relative;

    input {
      cursor: pointer;
      margin-right: 1rem;
      display: none;
    }

    &:hover {
      background-color: #cecece;
    }
    label {
      /* margin-left: 1rem; */
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &::before {
      content: "";
      background-image: url(${brazilFlag});
      background-repeat: no-repeat;
      height: auto;
      width: 2rem;
      z-index: 1;
    }
  }

  .itemMenuEN {
    display: flex;
    justify-content: space-around;
    padding: 0.7rem;
    cursor: pointer;
    border-bottom: 1px solid #cecece;
    position: relative;

    input {
      cursor: pointer;
      display: none;
    }

    &:hover {
      background-color: #cecece;
    }
    label {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &::before {
      content: "";
      background-image: url(${EnglishFlag});
      background-repeat: no-repeat;
      height: auto;
      width: 2rem;
      z-index: 1;
    }
  }

  .itemMenuES {
    display: flex;
    justify-content: space-around;
    padding: 0.7rem;
    cursor: pointer;
    border-bottom: 1px solid #cecece;
    position: relative;

    input {
      cursor: pointer;
      display: none;
    }

    &:hover {
      background-color: #cecece;
    }
    label {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &::before {
      content: "";
      background-image: url(${SpanishFlag});
      background-repeat: no-repeat;
      height: auto;
      width: 2rem;
      z-index: 1;
    }
  }

  form {
    flex-direction: column;
    width: 100%;
  }
`;
