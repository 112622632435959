import styled from 'styled-components';
import {device } from '../../../style/responsive'
export const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;

    }
 

    #play {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    @media only screen and ${device.phone}{
      height: 100vh;
  }
  }

  .play {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and ${device.tabPort}{
        flex-direction: column;
    }
  }

  video {
    height: 100vh;
    background-color: #fff;
    @media only screen and ${device.tabPort}{
      padding: 0;
      height: 0;
      min-height: 100vh;
    }
  }

  // .logos-bar{
  //   background: #fcfcfc;
  //   display: flex;
  //   width: 100%;
  //   position: absolute;
  //   top: 0;
    
  // }

  // .logos-img-container{
  //   display: flex;
  //   justify-content: space-around;
  //   width: 100%; 
  //   height: 3rem;
  // }

  // .logos-img {
  //   display: flex;
  //   margin-top: 0.5rem;
  //   margin-bottom: 0.5rem;
  //   margin-left: 0.5rem;
  //   width: 50;
  //   height: 50;
  //   @media only screen and ${device.tabPort}{
  //     width: 5;
  //     height: 5;
  // }
  // } 

  // .sportvLogo{
  //   display: flex;
  //   margin-top: 0.5rem;
  //   margin-bottom: 0.5rem;
  //   margin-left: 0.5rem;
  //   width: 20;
  //   height: 20;
  //   @media only screen and ${device.tabPort}{
  //     width: 30%;
  //     height: 100;
  // }
  // }

  ul{
    display: flex;
    justify-content: space-between !important;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #fcfcfc;
    position: fixed;
    top: 0;
    width: 100%;

  }

  li {
    display: flex;
    display:-webkit-flex;
    justify-content: center;
    margin-left: 0.5rem;
    width: 100%; 
    height: 3.5rem;
  }

 .img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;
    // height: 64px;
    @media only screen and ${device.phone} {
      width: 2.5rem;
    }

  } 
`;