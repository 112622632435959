import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../../layout/Footer";
import { Container } from "./styles";
import Menu from "../../../layout/Menu";
import Card from "../../../components/Card";
import leftArrow from "../../../assets/icon-arrow-left.svg";
import iconAdSmall from "../../../assets/icon-adNoBorder.svg";
import iconSubtitleSmall from "../../../assets/icon-cc-small.svg";
import iconLibrasSmall from "../../../assets/icon-handsSmall.svg";
import iconTranslationSmall from "../../../assets/icon-translation-small.svg";

import { SystemButton } from "../../../components/buttons/styles";
const ClientResources = () => {
  const history = useHistory();
  const { t } = useTranslation();

  // const handleResource = (route) => {
  //   history.push(route);
  // };

  const handleResource = (route, name) => {
    const width = window.screen.width;
    const height = window.screen.height;
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=400,height=600,screenX=${height + 100},screenY=${width - 50}`;

    window.open(route, name, params);

   
  };

  return (
    <>
      <Container>
        <Menu />
        <main className="event">
          <div className="event__top">
            <h2>{t("client-area-title")}</h2>
            <p>{t("client-area-subtitle")}</p>
          </div>

          <div className="event__available">
            <h4>{t("client-area-chooseEvent")}</h4>
            <Card
                clientEvent="Prefeitura"
                eventName="Jabaquara - Ceu Caminho do Mar"
                eventDate="06/08/2022"
                eventHour="09:00"
                subtitle
                ad
            />

            <div className="resourcesTitle">
              <h4>{t("client-resources-chosseTypeResources")}</h4>
            </div>

            <div className="resources">

              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/tbsp-ad", "ad")}
              >
                <img src={iconAdSmall} alt="Ícone de audiodescrição" />
                <p>{t("audiodescription-title")}</p>
              </SystemButton> */}

              <SystemButton
                className="resources__card"
                onClick={() => handleResource("/audio/jabaquara", "ad")}
              >
                <img src={iconTranslationSmall} alt="Ícone de audiodescrição" />
                {/* <p>{t("audiodescription-title")}</p> */}
                <p>Comunicação interna</p>
              </SystemButton>

              <SystemButton className="resources__card">
              <a
                  href="https://www.streamtext.net/player?event=jabaquara"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconSubtitleSmall} alt="Ícone de legendas" />
                    <p className="baseline">{t("subtitle-title")}</p>
                    </a>
                </SystemButton>

                {/* <SystemButton className="resources__card">
              <a
                  href="https://showmetext2.softlab.uc3m.es:443/anonymous/ktalise"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconSubtitleSmall} alt="Ícone de legendas" />
                    <p className="baseline">{t("subtitle-title")}</p>
                    </a>
                </SystemButton> */}

                {/* <SystemButton className="resources__card">
              <a
                  href="https://www.streamtext.net/player?event=oxiteno_spa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <img src={iconSubtitleSmall} alt="Ícone de legendas" />
                    <p className="baseline">{t("subtitle-title3")}</p>
                    </a>
                </SystemButton> */}

  


              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/chicago/libras", "libras")}
              >
                <img src={iconLibrasSmall} alt="Ícone de libras" />
                <p>{t("libras-title")}</p>
              </SystemButton> */}

              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/tbsp/english", "trad-portugues")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("translate-title")}</p>
              </SystemButton>  */}

              {/* <SystemButton
                className="resources__card"
                onClick={() => handleResource("/expocine/traducao-espanhol-portugues", "trad-espanhol")}
              >
                <img src={iconTranslationSmall} alt="Ícone de tradução" />
                <p>{t("trad-espanhol")}</p>
              </SystemButton> */}
            </div>
          </div>
        </main>
        <div className="back">
          <Link to="/eventos">
            <img src={leftArrow} alt="Voltar" />
            {t("btn-back")}
          </Link>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ClientResources;
